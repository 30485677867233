import React, { useState } from 'react'
import {  
  styled, 
  useTheme,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
// rotas
import { Link } from 'react-router-dom';
// icons
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Chat, School, Sell, ShoppingCart } from '@mui/icons-material';
import { StyledBadge } from '../../Styles/Badge';
import { useSelector } from 'react-redux'
import { selectProducts } from '../../Store/panierReducer';


const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const itemList = [
  {
    text: "Le Chai",
    icon: <HomeIcon />,
    to: "/"
  },
  {
    text: "Ateliers",
    icon: <School />,
    to: "/ateliers"
  },
  {
    text: "Boutique",
    icon: <Sell />,
    to: "/boutique"
  },
  {
    text: "Contact",
    icon: <Chat />,
    to: "/contact"
  }
];


export const DrawerItem = () => {

  const panierLength = useSelector(selectProducts).length

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
      aria-label="open drawer"
      edge="end"
      onClick={handleDrawerOpen}
      sx={{ ...(open && { display: 'none' }), color: '#121212' }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
      sx={{
          flexGrow: 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
          width: drawerWidth,
          },
      }}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={(e, reason) => (reason && reason === "backdropClick") ? setOpen(!open) : null}
      >
        <DrawerHeader>
          <IconButton sx={{color: '#121212'}} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList.map( ( item ) => {
            const { text } = item;
            return(
              <ListItem
              onClick={handleDrawerClose}
              key={text} 
              component={Link} 
              to={item.to}
              sx={{
                color: '#121212',
                "&:hover": {
                  backgroundColor: '#e9e5e5',
                  color: '#121212',
                }
              }}
              >
                <ListItemIcon
                sx={{
                  color: '#121212',
                  "&:hover":{
                    backgroundColor: 'transparent',
                    color: '#121212',
                  }
                }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            )
          })}
            <ListItem 
              onClick={handleDrawerClose}
              key='panier' 
              component={Link} 
              to='/panier'
              sx={{
                color: '#414141',
                "&:hover": {
                  backgroundColor: '#e9e5e5',
                  color: '#121212',
                }
              }}
              >
                <ListItemIcon
                sx={{
                  color: '#121212',
                  "&:hover":{
                    backgroundColor: 'transparent',
                    color: '#121212',
                  }
                }}
                >
                    <StyledBadge badgeContent={panierLength}>
                        <ShoppingCart/>
                    </StyledBadge>
                </ListItemIcon>
                <ListItemText primary='Panier' sx={{color: '#121212'}}/>
              </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default DrawerItem;