import BoxText from "../Components/BoxText";
import PagesHeader from "../Components/PagesHeader";
import { BackgroundBox } from "../Styles/Background";


export const Boutique = () => {

   return (
      <BackgroundBox>
         <PagesHeader
            title={"La boutique"}
            description={"Cette page est en cours de création. Retrouvez bientôt ici nos bouteilles et des produits dérivés."}
         />
         <BoxText
            title={"Bientôt disponible"}
            body={"Nous travaillons actuellement sur la mise en place de notre boutique en ligne. Vous pourrez bientôt commander nos produits ici. Essayez nos ateliers en attendant !"}
            link={"/ateliers"}
            textButton={"Voir les ateliers"}
         />
      </BackgroundBox>
   )
};

export default Boutique;