import { Card, Divider, Grid, MenuItem, Select, Typography } from '@mui/material';
import * as React from 'react';
import  { TextField } from '@mui/material';
import { BlackButton, OutlinedBlackButton } from '../../Styles/Button';
import { ClassicModal } from '../../Styles/Modal';
import { Link } from 'react-router-dom';
import api from '../../Api/api';



export const ModalCreation = props => {

    const { isOpen, setIsOpen } = props;
    const { produit } = props;
    const token = sessionStorage.getItem('token');

    const initialState = {
        _id: produit?._id || '',
        title: produit?.title || '',
        subtitle: produit?.subtitle || '',
        description: produit?.description || '',
        price: produit?.price || null,
        totalUnits: produit?.totalUnits || null,
        date: produit?.date || '',
        place: produit?.place || '',
        buyers: produit?.buyers || []
    };
    
    const [{ title, subtitle, description, price, totalUnits, date, place }, setState] = React.useState(initialState);
    const [type, setType] = React.useState(produit?.type || 'atelier')

    const handleChange = itemKey => event => {
        let result = event.target.value
        setState(prevState => {
            const states = { ...prevState };
            states[itemKey] = result;
            return states;
        });
    };

    const [isError, setIsError] = React.useState(false)
    const handleClick = () => {
        if (textError(title) || textError(subtitle) || textError(description) || numberError(price) || numberError(totalUnits) || dateError(date) || textError(place)) {
            setIsError(true)
        }
        else {
            setIsError(false)
            const newProduit = {
                title,
                subtitle,
                description,
                price,
                totalUnits,
                type
            }
            if (type==='atelier') {
                newProduit.date = date;
                newProduit.place = place;
            }
            handleModalValidation(newProduit)
            setIsOpen(false)
        }
    };

    const textError = (text) => {
        if (text) {
            return false
        }
        return true
    };
    const dateError = (date) => { return (!(/^([0-3][0-9])\/([0-1][0-9])\/([1-9][0-9]{3})\s([01][0-9]):([0-5][0-9])$/).test(date)) };
    const numberError = (number) => { return (!(/^\d+$/).test(number)) };
    

    const handleModalValidation = async (newProduit) => {
        let result
        if (!produit) {
            result = await api.createProduit(newProduit, token)
        } else {
            result = await api.updateProduit(produit?._id, newProduit, token)
        }
        return result;
    }
    
    return(
        <div>
        <ClassicModal sx={{
            width: "40%",
            height: "fit-content",
            margin: "auto",
            overflowY: "scroll",
            overflowX: "hidden",
            alignContent: "center"
            }}
            display="flex"
            open={isOpen}
            onClose={(e, reason) => (reason && reason === "backdropClick") ? setIsOpen(!isOpen) : null}>
                <Card id="modal" display="flex">
                    <Grid
                        display="flex"
                        container
                        padding={5}
                        rowSpacing={3}
                        columnSpacing={1}
                        direction="row"
                        margin="center"
                        justifyItems="center"
                        textAlign="center"
                    >   
                        <Grid item xs="12">
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "30px",
                                fontWeight: "bold"
                            }}>
                                {produit
                                ? "Modifier le produit choisi"
                                : "Ajouter un produit au site"
                                }
                            </Typography>
                            <Divider sx={{paddingTop: 1, borderBottomWidth: 2}} variant="fullWidth" orientation="horizontal" flexItem/>
                        </Grid>
                        <Grid item xs="8" paddingBottom={1}>
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "20px",
                            }}>Rentrer les informations :
                            </Typography>
                        </Grid>
                        {!produit?._id && 
                        <Grid item xs="4">
                            <Select
                                id="type-select"
                                variant="outlined"
                                value={type}
                                label="Type"
                                onChange={(event) => setType(event.target.value)}
                            >
                                <MenuItem value={'atelier'}>Atelier</MenuItem>
                                <MenuItem value={'boutique'}>Boutique</MenuItem>
                            </Select>
                        </Grid>}
                        <Grid item xs="6">
                            <TextField
                                id="title-textField"
                                label="Titre"
                                variant="outlined"
                                required
                                error={textError(title) && isError}
                                value={title}
                                onChange={value => handleChange('title')(value)}
                            />
                        </Grid>
                        <Grid item xs="6">
                            <TextField
                                id="subtitle-textField"
                                label="Sous-titre"
                                variant="outlined"
                                required
                                error={textError(subtitle) && isError}
                                value={subtitle}
                                onChange={value => handleChange('subtitle')(value)}
                            />
                        </Grid>
                        {(type === 'atelier') &&
                        <><Grid item xs="6">
                                <TextField
                                    id="date-textField"
                                    label="Date et Heure"
                                    variant="outlined"
                                    required
                                    error={dateError(date) && isError}
                                    value={date}
                                    onChange={value => handleChange('date')(value)} />
                            </Grid><Grid item xs="6">
                                    <TextField
                                        id="place-textField"
                                        label="Lieu"
                                        variant="outlined"
                                        required
                                        error={textError(place) && isError}
                                        value={place}
                                        onChange={value => handleChange('place')(value)} />
                                </Grid></>
                        }
                        <Grid item xs="6">
                            <TextField
                                id="totalUnits-textField"
                                label="Nombre disponible"
                                variant="outlined"
                                required
                                error={numberError(totalUnits) && isError}
                                value={totalUnits}
                                onChange={value => handleChange('totalUnits')(value)}
                            />
                        </Grid>
                        <Grid item xs="6">
                            <TextField
                                id="price-textField"
                                label="Prix unitaire"
                                variant="outlined"
                                required
                                error={numberError(price) && isError}
                                value={price}
                                onChange={value => handleChange('price')(value)}
                            />
                        </Grid>
                        <Grid item xs="12">
                        <Grid
                            container 
                            padding={1}
                            columnSpacing={1}
                            sx={{direction: 'column-reverse', alignItems: 'flex-end'}}>
                            <Grid item xs="8">
                                <TextField
                                    fullWidth
                                    id="description-textField"
                                    label="Description"
                                    variant="outlined"
                                    required
                                    multiline
                                    error={textError(description) && isError}
                                    value={description}
                                    onChange={value => handleChange('description')(value)}
                                />
                            </Grid>
                            <Grid item xs="4">
                                <BlackButton
                                    variant='standard'
                                    onClick={handleClick}
                                >
                                    {produit ? "Modifier" : "Créer"}
                                </BlackButton>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </Card>
        </ClassicModal>
        </div>
    )
}

export default ModalCreation