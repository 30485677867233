import { styled } from '@mui/material/styles';
import TextField from '@mui/material/Button';

export const CustomTextField = styled(TextField)({
    width: '100%', // Prend 100% de la largeur disponible
    height: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: '0.9rem',
    backgroundColor: 'transparent'
  }
);
  