import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';

export const ClassicModal = styled(Modal)(({ theme }) => ({
  height: "fit-content",
  maxHeight: "80vh", // Valeur par défaut pour la hauteur
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '40%',
  },
}));