import { Button, Card, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Modal, Typography } from '@mui/material';
import * as React from 'react';
import  { TextField, IconButton } from '@mui/material';
import { CheckBox, Close } from '@mui/icons-material';
import { BlackButton, OutlinedBlackButton } from '../../Styles/Button';
import { CustomTextField } from '../../Styles/TextField';
import { useMediaQuery } from '@mui/material';
import { ClassicModal } from '../../Styles/Modal';
import { Link } from 'react-router-dom';
import api from '../../Api/api';
import { useSelector } from 'react-redux'
import { selectProducts } from '../../Store/panierReducer';
import axios from 'axios';
import { ModalUnavailable } from '../ModalNonAvailableProduct';



export const ModalReservation = props => {

    const { isOpen, setIsOpen } = props
    const cart = useSelector(selectProducts)

    const initialState = {
        name: '',
        surname: '',
        mail: '',
        phone: ''
      };
    
    const [{ name, surname, mail, phone }, setState] = React.useState(initialState);
    const [isLoading, setIsLoading] = React.useState(false)
    const [isError, setIsError] = React.useState()
    const [unavailableProduct, setUnavailableProduct] = React.useState([])
    const [isModalUnavailableOpen, setIsModalUnavailableOpen] = React.useState(false)
    const [isChecked, setIsChecked] = React.useState(false);

    const handleChange = itemKey => event => {
        let result = event.target.value
        result = typeof result === 'string' ? result.trim() : result;
        setState(prevState => {
            const states = { ...prevState };
            states[itemKey] = result;
            return states;
        });
    };

    const verificationCart = async (cart) => {
        const missingProducts = []
        for (const element of cart) {
            const produit = await api.getProduit(element.product._id);
            const availableQuantity = produit.totalUnits - produit.bookedUnits;
            if (availableQuantity < element.quantity) {
                missingProducts.push({
                    product: element.product,
                    availableQuantity,
                    wantedQuantity: element.quantity
                });
            }
        }
        // Mise à jour synchrone des états après la vérification
        return new Promise((resolve) => {
            if (missingProducts.length !== 0 ) {
                setUnavailableProduct(missingProducts);
                setIsModalUnavailableOpen(true);
                resolve(false);
            } else {
                setUnavailableProduct([]);
                resolve(true);
            }
        });
    };

    React.useEffect(() => {
        verificationCart(cart);
    }, [])

    const [isSmall, setIsSmall] = React.useState(false);

    React.useEffect(() => {
        const checkScreenSize = () => {
            setIsSmall(window.matchMedia('(max-width: 600px)').matches);
        };
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const handleClick = async () => {
        setIsLoading(true);
        const allAvailable = await verificationCart(cart);
        if (!allAvailable) {
            return;
        }

        if (
            nameError(name) 
            || nameError(surname)
            || mailError(mail)
            || phoneError(phone)
            || !isChecked
        ) {
            setIsError(true)
            setIsLoading(false)
        }
        else {
            setIsError(false)
            const { _id } = await handleModalValidation({
                name,
                surname,
                mail,
                phone
            })
            const stripeCart = []
            for (const element of cart) {
                stripeCart.push({
                    name: element.product.title,
                    description: element.product.subtitle,
                    price: element.product.price * 100,
                    quantity: element.quantity,
                    produitId: element.product._id
                })
            }
            const customerInfo = JSON.stringify({ mail, orderId: _id })
            
            const { stripeUrl, sessionId } = await api.createCheckoutSession(customerInfo, stripeCart);
            if (stripeUrl) {
                window.history.replaceState(null, null, `https://jemenfoucomplet.fr/payement?canceled=true&session_id=${sessionId}`);
                window.location.href = stripeUrl;
            }
        }
    };

    const handleModalValidation = async (newCustomer) => {
        const customerExist = await api.getCustomerByMail(newCustomer?.mail);
        let customer
        if (!customerExist) {
            customer = await api.createCustomer(newCustomer);
        } else {
            customer = await api.updateCustomer(customerExist?._id, newCustomer);
        }
        const newOrder = {
            products: [],
            customerId: customer._id,
            totalPrice: 0,
            status: 'unpaid',
            sessionId: ''
        }
        for (const produit of cart) {
            await api.handleBuyerToProduit(
                produit?.product?._id,
                customer?._id,
                produit?.quantity,
                "add"
            );
            newOrder.products.push({
                produitId: produit?.product?._id,
                quantity: produit?.quantity,
                unitPrice: produit?.product?.price
            })
        }
        const order = await api.createOrder(newOrder);
        return order;
    }

    const nameError = (name) => { return (!(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/).test(name)); }
    const mailError = (mail) => { return !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(mail); }
    const phoneError = (phone) => { return !(/^\d{10}$/).test(phone); }

    return(
        <div>
        <ClassicModal sx={{
            overflowY: 'auto',
            width: "40%",
            height: "fit-content",
            margin: "auto",
            alignContent: "center"
            }}
            display="flex"
            open={isOpen}
            onClose={(e, reason) => (reason && reason === "backdropClick") ? setIsOpen(!isOpen) : null}>
                <Card id="modal" display="flex">
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                        onClick={() => setIsOpen(false)}
                    >
                        <Close/>
                    </IconButton>
                    <Grid
                        display="flex"
                        container
                        padding={5}
                        rowSpacing={{xs: 2, md: 2}}
                        columnSpacing={1}
                        direction="row"
                        margin="center"
                        justifyItems="center"
                        textAlign="center"
                    >   
                        <Grid item xs="12">
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "30px",
                                fontWeight: "bold"
                            }}>Finalisez votre achat !</Typography>
                            <Divider sx={{paddingTop: 1, borderBottomWidth: 2}} variant="fullWidth" orientation="horizontal" flexItem/>
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "20px",
                                paddingTop: 2
                            }}>Rentrez vos informations :</Typography>
                        </Grid>
                        <Grid item xs="6">
                            <TextField
                                size={ isSmall ? 'small' : 'medium' }
                                fullWidth
                                id="name-textField"
                                label="Prénom"
                                placeholder=" ex: Jean"
                                variant="outlined"
                                required
                                error={nameError(name) && isError}
                                helperText={(nameError(name) && isError) ? "Rentrer un prénom valide" : null}
                                value={name}
                                onChange={value => handleChange('name')(value)}
                            />
                        </Grid>
                        <Grid item xs="6">
                            <TextField
                                size={ isSmall ? 'small' : 'medium' }
                                fullWidth
                                id="surname-textField"
                                label="Nom"
                                placeholder="ex: Dupont"
                                variant="outlined"
                                required
                                error={nameError(surname) && isError}
                                helperText={(nameError(surname) && isError) ? "Rentrer un nom valide" : null}
                                value={surname}
                                onChange={value => handleChange('surname')(value)}
                            />
                        </Grid>
                        <Grid item xs="12">
                            <TextField
                                size={ isSmall ? 'small' : 'medium' }
                                fullWidth
                                id="mail-textField"
                                label="Adresse mail"
                                placeholder="ex: jean.dupont@gmail.com"
                                variant="outlined"
                                required
                                error={mailError(mail) && isError}
                                helperText={(mailError(mail) && isError) ? "Rentrer une adresse mail valide" : null}
                                value={mail}
                                onChange={value => handleChange('mail')(value)}
                            />
                        </Grid>
                        <Grid item xs="12">
                            <TextField
                                size={ isSmall ? 'small' : 'medium' }
                                fullWidth
                                id="phone-textField"
                                label="Téléphone"
                                placeholder="ex: 06 55 44 77 11"
                                variant="outlined"
                                required
                                error={phoneError(phone) && isError}
                                helperText={(phoneError(phone) && isError) ? "Rentrer un numéro valide" : null}
                                value={phone}
                                onChange={value => handleChange('phone')(value)}
                            />
                        </Grid>
                        {isLoading && 
                        <Grid item xs="12">
                            <CircularProgress color="inherit" />
                        </Grid>
                        }
                        <Grid item xs="12">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                        color="default"
                                        sx={{
                                            color: isError && !isChecked
                                                ? '#c62828'
                                                : '#16191e'
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            paddingY: 1,
                                            color: isError && !isChecked
                                                ? '#c62828'
                                                : '#16191e'
                                        }}
                                    >
                                        J'accepte les CGV <br />
                                        <Link 
                                            component={Link} 
                                            to="/terms"
                                            style={{ marginLeft: 10 }}
                                        >
                                            En savoir plus
                                        </Link>
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid item xs="12">
                            <BlackButton
                            sx={{
                                marginY: 0
                            }}
                                onClick={handleClick}
                            >
                                Passer au paiement
                            </BlackButton>
                        </Grid>
                    </Grid>
                </Card>
        </ClassicModal>
        <ModalUnavailable
            isModalUnavailableOpen={isModalUnavailableOpen}
            setIsModalUnavailableOpen={setIsModalUnavailableOpen}
            unavailableProduct={unavailableProduct}
        />
        </div>
    )
}

export default ModalReservation
