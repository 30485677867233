import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import Header from '../Components/Header';
import ModalMajeur from '../Components/ModalMajeur';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { BackgroundBox } from '../Styles/Background';
import { BlackButton } from '../Styles/Button';
import { TextBox } from '../Styles/Box';
import Footer from '../Components/Footer';
import VideoPlayer from '../Components/VideoPlayer';
import ImageDisplayer from '../Components/ImageDisplayer';
import grape from '../Assets/grape.webp';
import land from '../Assets/land.webp';
import sunset from '../Assets/sunset.webp';
import barrel from '../Assets/barrel.webp';


export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem("isModalAccepted")) {
      setIsOpen(true);
    }
  }, []);

  return (
    <>
      <ModalMajeur isOpen={isOpen} setIsOpen={setIsOpen} />
      <BackgroundBox>
      <Header/>
      <Box sx={{ backgroundColor: 'transparent', position: 'relative', zIndex: 1}}>
      <Grid container>
        <Grid item xs={12}>
        <Box // Contenant infos et photo //
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row-reverse' },
            justifyContent: 'center',
            alignItems: 'center',
            margin: { xs: 1, md: 5 },
            backgroundColor: '#FBFCFA',
            height: '700px',
            columnGap: 10,
          }}
        >
          <ImageDisplayer img={grape} description='grape' type='large'/>
          <Box // Text et Bouton dans le contenant //
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: {xs: 'center', md: 'start'},
              alignItems: {xs: 'center', md: 'start'},
              maxWidth: { xs: '90%', md: '25%' },
            }}
          >
            <Typography variant='h3' component='h1'
              sx={{
                py: 1,
                color: '#16191e',
                fontSize: {xs: 35, md: 45},
                textAlign: {sm: 'center', md: 'start'},
                letterSpacing: 1.5,
              }}
            >
              Welcome to the Mountain of Youth
            </Typography>
            <Typography variant='p' component='p'
              sx={{
                lineHeight: 1.1,
                py: 1,
                fontSize: 18,
                lineHeight: 1.3,
                color: '#16191e',
                textAlign: {xs: 'center', md: 'start'}
              }}
            >
            En 2024, evian réaffirme sa signature emblématique Live Young, qui incite chacun d’entre nous à cultiver son bien-être, quel que soit notre âge.
            </Typography>
            <Typography variant='p' component='p'
              sx={{
                fontSize: 18,
                lineHeight: 1.1,
                py: 1,
                lineHeight: 1.3,
                color: '#16191e',
                textAlign: {xs: 'center', md: 'start'},
              }}
            >
            ​La marque vous invite à partir à la découverte de la Mountain of Youth. Ce lieu magique et pur, à l’image de l'eau minérale naturelle evian, procure un sentiment de bien-être physique et mental. 
            </Typography>
            <Button
              variant="outlined"
              sx={{
                width: '80%',
                borderRadius: 0,
                borderColor: '#16191e',
                color: '#16191e',
                fontWeight: 600,
                my: 5,
                py : 1.5,
                "&&:hover": {
                    backgroundColor: "#9a060d",
                    borderColor: "#9a060d",
                    color: "#FBFCFA"
                },
                "&&:focus": {
                    backgroundColor: "#9a060d",
                    borderColor: "#9a060d",
                    color: "#FBFCFA"
                }
              }}
            >
              En savoir plus
            </Button>
          </Box>
        </Box>
        </Grid>
        <Grid item xs={12}>
          <Box // Text en dehors du contenant //
            sx={{
              position: 'relative',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: 'auto',
              marginY: 2,
              maxWidth: { xs: '90%', md: '80%' },
            }}
          >
            <Typography variant='h6' component='h1'
              sx={{
                py: 1,
                color: '#16191e',
                textAlign: 'center',
                fontWeight: 'bold',
                letterSpacing: 1.5,
              }}
            >
              Welcome to the Mountain of Youth
            </Typography>
            <Typography variant='p' component='p'
              sx={{
                fontSize: 18,
                lineHeight: 1.1,
                py: 1,
                lineHeight: 1.3,
                color: '#16191e',
                textAlign: 'center',
              }}
            >
            En 2024, evian réaffirme sa signature emblématique Live Young, qui incite chacun d’entre nous à cultiver son bien-être, quel que soit notre âge.
            ​La marque vous invite à partir à la découverte de la Mountain of Youth. Ce lieu magique et pur, à l’image de l'eau minérale naturelle evian, procure un sentiment de bien-être physique et mental. 
            </Typography>
          </Box>
        <VideoPlayer link='https://www.youtube.com/watch?v=c5vL42EB0Fk'/>
        </Grid>
        <Grid item xs={12}>
        <Box // Contenant infos et photo //
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            margin: { xs: 1, md: 5 },
            backgroundColor: '#FBFCFA',
            height: '700px',
            columnGap: 10,
          }}
        >
          <ImageDisplayer img={land} description='land' type='large'/>
          <Box // Text et Bouton dans le contenant //
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: {xs: 'center', md: 'start'},
              alignItems: {xs: 'center', md: 'start'},
              maxWidth: { xs: '90%', md: '25%' },
            }}
          >
            <Typography variant='h3' component='h1'
              sx={{
                py: 1,
                color: '#16191e',
                fontSize: {xs: 35, md: 45},
                textAlign: {sm: 'center', md: 'start'},
                letterSpacing: 1.5,
              }}
            >
              Welcome to the Mountain of Youth
            </Typography>
            <Typography variant='p' component='p'
              sx={{
                fontSize: 18,
                lineHeight: 1.1,
                py: 1,
                lineHeight: 1.3,
                color: '#16191e',
                textAlign: {xs: 'center', md: 'start'}
              }}
            >
            En 2024, evian réaffirme sa signature emblématique Live Young, qui incite chacun d’entre nous à cultiver son bien-être, quel que soit notre âge.
            </Typography>
            <Typography variant='p' component='p'
              sx={{
                fontSize: 18,
                lineHeight: 1.1,
                py: 1,
                lineHeight: 1.3,
                color: '#16191e',
                textAlign: {xs: 'center', md: 'start'},
              }}
            >
            ​La marque vous invite à partir à la découverte de la Mountain of Youth. Ce lieu magique et pur, à l’image de l'eau minérale naturelle evian, procure un sentiment de bien-être physique et mental. 
            </Typography>
            <Button
              variant="outlined"
              sx={{
                width: '80%',
                borderRadius: 0,
                borderColor: '#16191e',
                color: '#16191e',
                fontWeight: 600,
                my: 5,
                py : 1.5,
                "&&:hover": {
                    backgroundColor: "#9a060d",
                    borderColor: "#9a060d",
                    color: "#FBFCFA"
                },
                "&&:focus": {
                    backgroundColor: "#9a060d",
                    borderColor: "#9a060d",
                    color: "#FBFCFA"
                }
              }}  
            >
              En savoir plus
            </Button>
          </Box>
        </Box>
        </Grid>
        <Grid item xs={12}>
          <Box // Text en dehors du contenant //
            sx={{
              position: 'relative',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: 'auto',
              marginY: 2,
              maxWidth: { xs: '90%', md: '80%' },
            }}
          >
            <Typography variant='h6' component='h1'
              sx={{
                py: 1,
                color: '#16191e',
                textAlign: 'center',
                fontWeight: 'bold',
                letterSpacing: 1.5,
              }}
            >
              Welcome to the Mountain of Youth
            </Typography>
            <Typography variant='p' component='p'
              sx={{
                fontSize: 18,
                lineHeight: 1.1,
                py: 1,
                lineHeight: 1.3,
                color: '#16191e',
                textAlign: 'center',
              }}
            >
            En 2024, evian réaffirme sa signature emblématique Live Young, qui incite chacun d’entre nous à cultiver son bien-être, quel que soit notre âge.
            ​La marque vous invite à partir à la découverte de la Mountain of Youth. Ce lieu magique et pur, à l’image de l'eau minérale naturelle evian, procure un sentiment de bien-être physique et mental. 
            </Typography>
          </Box>
        <VideoPlayer link='https://www.youtube.com/watch?v=o22usakbFhU'/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box // Contenant infos et photo 1/2 //
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: { xs: 1, md: 5 },
              backgroundColor: '#FBFCFA',
              height: '700px',
              columnGap: 10,
            }}
          >
            <ImageDisplayer img={sunset} description='sunset' type='small'/>
            <Box // Text et Bouton dans le contenant //
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: {xs: 'center', md: 'start'},
                alignItems: {xs: 'center', md: 'start'},
                maxWidth: { xs: '90%', md: '80%' },
              }}
            >
              <Typography variant='h3' component='h1'
                sx={{
                  py: 1,
                  color: '#16191e',
                  fontSize: {xs: 35, md: 45},
                  textAlign: {sm: 'center', md: 'start'},
                  letterSpacing: 1.5,
                }}
              >
                Welcome to the Mountain of Youth
              </Typography>
              <Typography variant='p' component='p'
                sx={{
                  fontSize: 18,
                  lineHeight: 1.1,
                  py: 1,
                  lineHeight: 1.3,
                  color: '#16191e',
                  textAlign: {xs: 'center', md: 'start'}
                }}
              >
              En 2024, evian réaffirme sa signature emblématique Live Young, qui incite chacun d’entre nous à cultiver son bien-être, quel que soit notre âge.
              </Typography>
              <Typography variant='p' component='p'
                sx={{
                  fontSize: 18,
                  lineHeight: 1.1,
                  py: 1,
                  lineHeight: 1.3,
                  color: '#16191e',
                  textAlign: {xs: 'center', md: 'start'},
                }}
              >
              ​La marque vous invite à partir à la découverte de la Mountain of Youth. Ce lieu magique et pur, à l’image de l'eau minérale naturelle evian, procure un sentiment de bien-être physique et mental. 
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  width: '80%',
                  borderRadius: 0,
                  borderColor: '#16191e',
                  color: '#16191e',
                  fontWeight: 600,
                  my: 5,
                  py : 1.5,
                  "&&:hover": {
                      backgroundColor: "#9a060d",
                      borderColor: "#9a060d",
                      color: "#FBFCFA"
                  },
                  "&&:focus": {
                      backgroundColor: "#9a060d",
                      borderColor: "#9a060d",
                      color: "#FBFCFA"
                  }
                }}  
              >
                En savoir plus
              </Button>
          </Box>
        </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box // Contenant infos et photo 1/2 //
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: { xs: 1, md: 5 },
              backgroundColor: '#FBFCFA',
              height: '700px',
              columnGap: 10,
            }}
          >
            <ImageDisplayer img={barrel} description='barrel' type='small'/>
            <Box // Text et Bouton dans le contenant //
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: {xs: 'center', md: 'start'},
                alignItems: {xs: 'center', md: 'start'},
                maxWidth: { xs: '90%', md: '80%' },
              }}
            >
              <Typography variant='h3' component='h1'
                sx={{
                  py: 1,
                  color: '#16191e',
                  fontSize: {xs: 35, md: 45},
                  textAlign: {sm: 'center', md: 'start'},
                  letterSpacing: 1.5,
                }}
              >
                Welcome to the Mountain of Youth
              </Typography>
              <Typography variant='p' component='p'
                sx={{
                  fontSize: 18,
                  lineHeight: 1.1,
                  py: 1,
                  lineHeight: 1.3,
                  color: '#16191e',
                  textAlign: {xs: 'center', md: 'start'}
                }}
              >
              En 2024, evian réaffirme sa signature emblématique Live Young, qui incite chacun d’entre nous à cultiver son bien-être, quel que soit notre âge.
              </Typography>
              <Typography variant='p' component='p'
                sx={{
                  fontSize: 18,
                  lineHeight: 1.1,
                  py: 1,
                  lineHeight: 1.3,
                  color: '#16191e',
                  textAlign: {xs: 'center', md: 'start'},
                }}
              >
              ​La marque vous invite à partir à la découverte de la Mountain of Youth. Ce lieu magique et pur, à l’image de l'eau minérale naturelle evian, procure un sentiment de bien-être physique et mental. 
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  width: '80%',
                  borderRadius: 0,
                  borderColor: '#16191e',
                  color: '#16191e',
                  fontWeight: 600,
                  my: 5,
                  py : 1.5,
                  "&&:hover": {
                      backgroundColor: "#9a060d",
                      borderColor: "#9a060d",
                      color: "#FBFCFA"
                  },
                  "&&:focus": {
                      backgroundColor: "#9a060d",
                      borderColor: "#9a060d",
                      color: "#FBFCFA"
                  }
                }}  
              >
                En savoir plus
              </Button>
          </Box>
        </Box>
        </Grid>
      </Grid>
      </Box>
      </BackgroundBox>
    </>
  );
};

export default Home;

// 800e13 5c0000 9a060d //