import { useDispatch, useSelector } from 'react-redux'
import { addProduct, removeProduct, selectProducts } from '../../Store/panierReducer';
import { Card, Modal, Typography } from '@mui/material';
import { BlackButton } from '../../Styles/Button';

export const ModalUnavailable = (props) => {

   const { unavailableProduct } = props;
   const { isModalUnavailableOpen, setIsModalUnavailableOpen } = props

   const cart = useSelector(selectProducts)

   const dispatch = useDispatch()
   const removeUnavailableProduct = async (unavailableProduct) => {
      for (const product of unavailableProduct) {
         dispatch(removeProduct(product))
         if (product.availableQuantity > 0) {
            dispatch(addProduct({product: product.product, quantity: product.availableQuantity}))
         }
         unavailableProduct.shift()
      }
   }

   const handleClose = () => {
      removeUnavailableProduct(unavailableProduct)
      setIsModalUnavailableOpen(false)
   }

   return (
      <Modal
         sx={{
               margin: "auto",
               height: "fit-content",
               width: {xs: "100%", md: "50%"},
               alignContent: "center",
               backgroundColor: 'white'
         }}
         display= "flex"
         open={unavailableProduct.length !== 0}
         onClose={(e, reason) => {
            if (reason === "backdropClick") {
               handleClose();
            }
         }}
      >
         <Card
            sx={{
               display: "flex",
               flexDirection: "column",
               alignContent: "center",
               alignItems: "center",
               textAlign: "center",
               justifyContent: "center",
               padding: 4
            }}
         >
            <p>
                  Attention un ou plusieurs éléments de votre panier ont vu leur quantité changée car ils ne sont plus disponible dans la quantité voulu ! 
                  Le ou les éléments concernés sont
                  {unavailableProduct.map(el => {
                     return (
                        <>
                        <Typography
                           sx={{
                              paddingTop: 1,
                              fontSize: 15,
                              fontWeight: "bold"
                           }}
                        >
                           {el.product.title}
                        </Typography>
                        <Typography
                           sx={{
                              lineHeight: 1,
                              fontSize: 14
                           }}
                        >
                           quantité voulue : {el.wantedQuantity} 
                        </Typography>
                        <Typography
                           sx={{
                              lineHeight: 1,
                              fontSize: 14
                           }}
                        >
                           quantité disponible : {el.availableQuantity}
                        </Typography>
                     </>
                     )
                  })
                  }
            </p>
            <BlackButton
               sx={{
                  width: "50%"
               }}
               onClick={handleClose}
            >
               J'ai compris
            </BlackButton>
         </Card>
      </Modal>
   )
}