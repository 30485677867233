import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { alpha, Box, Divider } from '@mui/material';
import ModalNumber from '../ModalNumber';
import { CalendarToday, LocationOn } from '@mui/icons-material';

export const FormationCard = ({ product }) => {
  const { title, subtitle, place, date, price, totalUnits, bookedUnits } = product;
  const [isOpen, setIsOpen] = React.useState(false);
  const availableUnits = totalUnits - bookedUnits;

  return (
    <>
      <Card
        onClick={() => {
          availableUnits > 0 ? setIsOpen(true) : setIsOpen(false)
        }}
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '28px',
          overflow: 'hidden',
          backgroundColor: '#FBFCFA',
          paddingY: '20px', // Haut et bas
          paddingX: {sm: '20px', md: '50px'}, // Gauche et droite
          position: 'relative',
          flexDirection: 'column',
          marginBottom: '30px',
          maxWidth: {xs: '100%', sm: '70%', md: '50%'},
          ':hover .card-bg': {
            transform: 'scale(10)',
          },
          '&:hover .hover-typography': {
            color: '#0B1616', // Change color to white on hover
          },
          '&:hover .hover-black-typography': {
            color: '#FBFCFA', // Change color to white on hover
          },
          '&:hover .hover-white-typography': {
            color: '#0B1616', // Change color to white on hover
          },
          '&:hover .hover-black-box': {
            backgroundColor: '#FBFCFA', // Change color to white on hover
          },
        }}
      >
        {
          availableUnits <= 0 &&
          <Box
            sx={{
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              backgroundColor: alpha('#616161', 0.6), // Transparence avec alpha
              zIndex: 100,
              display: 'flex',
              position: 'absolute',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                position: 'relative',
                textAlign: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                fontSize: 50,
                width: '100%',
                fontWeight: 'bold',
                color: '#FBFCFA',
                transform: 'rotate(-45deg)', // Applique une rotation de 45 degrés en sens horaire
                display: 'inline-block', 
              }}
            >
              Sold Out
            </Typography>
          </Box>
        }
        <Box
          className="card-bg"
          sx={{
            height: '128px',
            width: '128px',
            backgroundColor: '#9a060d',
            zIndex: 1,
            position: 'absolute',
            top: '-80px',
            right: '-65px',
            borderRadius: '20%',
            transition: 'all .5s ease',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        />
        <CardContent
          sx={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            margin: 0,
            padding: 0,
            flexDirection: 'column',
            alignItems: 'center', // Changement de alignContent à alignItems
            justifyContent: 'center',
            textAlign: 'center' // Ajout pour centrer le texte
          }}
        >
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              textAlign: 'center',
            }}
          >
            <Typography
              className='hover-black-typography'
              variant="h5"
              sx={{
                paddingTop: 2,
                display: 'inline-block',
                transition: 'color .2s ease',
                fontWeight: 'bold',
                fontSize: '30px',
                color: '#0B1616',
                zIndex: 2,
                position: 'relative',
              }}
            >
              {title}
            </Typography>
            <Divider orientation="horizontal" sx={{width: '80%', borderBottomWidth:2, paddingTop: 0.5, zIndex: 2}} />
            <Typography
              variant="h6"
              sx={{
                paddingTop: 0.5,
                paddingBottom: 1.5,
                display: 'inline-block',
                transition: 'color .2s ease',
                fontWeight: 'bold',
                fontSize: '18px',
                color: '#9e9e9e',
                zIndex: 2,
                position: 'relative',
              }}
            >
              {subtitle}
            </Typography>
          </Box>
          <Typography
            className='hover-typography'
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              transition: 'color .2s ease',
              fontSize: '18px',
              color: '#9a060d',
              zIndex: 2,
              position: 'relative',
            }}
          >
            <CalendarToday/>
            <Typography
              className="hover-black-typography"
              sx={{
                position: 'relative',
                fontWeight: 'bold',
                color: '#0B1616', // Default color
                transition: 'color .2s ease',
                display: 'inline', // Ensures Typography behaves like inline element
                padding: 1,
              }}
            >
              {date}
            </Typography>
          </Typography>
          <Typography
            className='hover-typography'
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              transition: 'color .2s ease',
              fontSize: '18px',
              color: '#9a060d',
              zIndex: 2,
              position: 'relative',
            }}
          >
            <LocationOn/>
            <Typography
              className="hover-black-typography"
              sx={{
                padding: 1,
                textAlign: 'left',
                fontWeight: 'bold',
                color: '#0B1616', // Default color
                transition: 'color .2s ease',
                display: 'inline', // Ensures Typography behaves like inline element
                lineHeight: 1.2
              }}
            >
              {place}
            </Typography>
          </Typography>
          <Box
            className="hover-black-box"
            sx={{
              marginTop: 3,
              alignItems: 'center',
              height: '50px',
              width: '40%',
              backgroundColor: '#9a060d',
              zIndex: 1,
              position: 'relative',
              borderRadius: '5%',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              textAlign: 'center',
              transition: 'color .3s ease',
            }}
          >
            <Typography
              className='hover-white-typography'
              sx={{
                position: 'relative',
                fontSize: '25px',
                fontWeight: 'bold',
                color: '#FBFCFA', // Default color
                transition: 'color .2s ease',
                display: 'inline', // Ensures Typography behaves like inline element
              }}
            >
              {price} €
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <ModalNumber isOpen={isOpen} setIsOpen={setIsOpen} product={product} />
    </>
  );
}

export default FormationCard;