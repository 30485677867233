import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BlackButton } from '../../Styles/Button';

export const BoxText = ({title, body, link, textButton}) => {

   return (
   <>
   <Box // Contenant infos et photo //
      sx={{
         display: 'flex',
         flexDirection: { xs: 'column', md: 'row' },
         justifyContent: 'center',
         alignItems: 'center',
         margin: { xs: 0, md: 5 },
         backgroundColor: '#FBFCFA',
         height: '500px',
         columnGap: 10,
      }}
   >
      <Box // Text et Bouton dans le contenant //
         sx={{
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         textAlign: {xs: 'center', md: 'center'},
         alignItems: {xs: 'center', md: 'center'},
         maxWidth: { xs: '90%', md: '50%' },
         }}
      >
         <Typography variant='h3' component='h1'
            sx={{
               py: 1,
               color: '#16191e',
               textAlign: {sm: 'center', md: 'center'},
               letterSpacing: 1.5,
            }}
         >
         {title}
         </Typography>
         <Typography variant='p' component='p'
            sx={{
               fontSize: 18,
               lineHeight: 1.1,
               py: 1,
               lineHeight: 1.3,
               color: '#16191e',
               textAlign: 'center'
            }}
         >
         {body}
         </Typography>
         <BlackButton
            component={Link}
            to={link}
         >
            {textButton}
         </BlackButton>
      </Box>       
   </Box>
   </>
   )
}

export default BoxText;