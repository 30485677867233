import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../Api/api';
import PagesHeader from '../Components/PagesHeader';
import { useDispatch } from 'react-redux';
import { removeAllProduct } from '../Store/panierReducer'
import { Box, Typography } from '@mui/material';
import { BlackButton } from '../Styles/Button';
import { type } from '@testing-library/user-event/dist/type';
import BoxText from '../Components/BoxText';

export const Payement = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const success = queryParams.get('success');
  const canceled = queryParams.get('canceled');
  const stripeId = queryParams.get('session_id');
  

  const handleSuccess = async (orderId, sessionId) => {
    dispatch(removeAllProduct());
    const order = await api.updateOrder(orderId, {status: 'validated', sessionId});
    await api.handleOrderToCustomer(order.customerId, orderId);
    const client = await api.getCustomer(order.customerId);
    let payload = {
      client: {
        mail: client.mail,
        name: client.name
      },
      products: []
    };
    for (const element of order.products) {
      const product = await api.getProduit(element.produitId);
      payload.products.push({
        type: product.type,
        title: product.title,
        subtitle: product.subtitle,
        quantity: element.quantity,
        place: product?.place,
        date: product?.date,
      });
    }
    await api.sendConfirmationMail(payload);
  };

  const handleCanceled = async (orderId, sessionId) => {
    const order = await api.updateOrder(orderId, {status: 'canceled', sessionId});
    await api.closeCheckoutSession(sessionId);
    for (const element of order.products) {
      await api.handleBuyerToProduit(
        element.produitId,
        order.customerId,
        element.quantity,
        "remove"
      );
    }
  };

  const stripeTest = async () => {
    const { order } = await api.getCheckoutSession(stripeId);
    const myOrder = await api.getOrder(order.orderId);

    const { orderId } = order;
    if (success && order.status === 'paid' && myOrder.status !== 'validated') {
      handleSuccess(orderId, stripeId)
    }
    if (canceled && myOrder.status !== 'canceled' && myOrder.status !== 'abandonned') {
      handleCanceled(orderId, stripeId);
    }
  };

  React.useEffect(() => {
    if (stripeId) {
      stripeTest();
    }
  }, [stripeId, success, canceled]);

  return (
    <>
    <BoxText
      title={success 
        ? "Merci de votre achat !" 
        : "Votre paiement n'a pas pu aboutir ..."}
      body={success 
        ? "Un mail de confirmation vous sera envoyé sur votre adresse mail dans les plus brefs délais" 
        : "Un problème nous a empêché de finaliser votre commande. Veuillez retourner au panier et procéder au paiement"}
      link={success ? '/' : '/panier'}
      textButton={success ? "Retour à l'accueil" : "Retour au panier"}
    />
    </>
  );
};

export default Payement;
