import React from 'react';
import Box from '@mui/material/Box';

export const ImageDisplayer = ({img, description, type}) => {

   const widthMap = {
      small: '80%',
      medium: '65%',
      large: '50%',
   };

   const mdWidth = widthMap[type];
   
   return (
      <Box
         sx={{
         display: 'flex',
         backgroundColor: '#9a060d',
         justifyContent: 'center',
         alignItems: 'center',
         margin: 2,
         width: {xs: '95%', md: mdWidth},
         height: {xs: '100%', md: '80%'},
         overflow: 'hidden',
         }}
      >
         <img 
            src={img} 
            style={{
               width: '100%',
               objectFit: 'cover'
            }} 
            alt={description}
         />
      </Box>
   )
}

export default ImageDisplayer