import React from 'react'
import { Box, styled, Typography } from "@mui/material";
import { Link } from 'react-router-dom'
import { BlackButton, OutlinedWhiteButton } from '../../Styles/Button';
export const Header = () => {

    const CustomBox = styled(Box) (({ theme }) => ({
        minHeight: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        background: 'transparent',
        backgroundColor: '#9a060d',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            textAlign: 'center',
        }
    }));

    const BoxText = styled(Box) (({ theme }) => ({
        flex: '1',
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            flex: '1',
            textAlign: 'center',
            paddingTop: theme.spacing(5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }));


  return (
    <>
        <CustomBox component='header'>
          <BoxText component='section'>
              <Typography variant='h2' component='h1'
                  sx={{
                      fontWeight: 700,
                      color: '#fff',
                  }}
              >
                  Le vin c'est notre métier
              </Typography>
              <Typography variant='p' component='p'
                  sx={{
                      py: 3,
                      lineHeight: 1.6,
                      color: '#fff',
                  }}
              >
                  Bienvenue au Chai !
                  Dégustation de vin, atelier œnologie, vente de bouteilles et bien plus encore ...
              </Typography>
              <Box>
                  <BlackButton
                      variant='outlined'
                      component={Link}
                      to={'/ateliers'}
                  >
                      buy now
                  </BlackButton>
                  <OutlinedWhiteButton
                      variant='outlined'
                      component={Link}
                      to={'/contact'}
                  >
                      explore
                  </OutlinedWhiteButton>
              </Box>
          </BoxText>
          <Box sx={theme => ({
              [theme.breakpoints.down('md')]: {
                  flex: '1',
                  paddingTop: '30px',
                  alignSelf: 'center',
              },
              [theme.breakpoints.up('md')]: {
                  flex: '2',
                  alignSelf: 'flex-end',
              },
          })}
          >
            {/* <img
                src={}
                alt="headerImg"
                style={{
                    width: "60%",
                    margin: 50
                }}
            /> */}
          </Box>
        </CustomBox>
    </>
    )
}

export default Header