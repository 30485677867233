import React from "react";
import CardContainer from "../Containers/CardContainer";
import { Typography, Box } from "@mui/material";
import { RightTextBox, TextBox } from "../Styles/Box";
import { BackgroundBox } from "../Styles/Background";
import PagesHeader from "../Components/PagesHeader";
import workshop from "../Assets/workshop.webp";

export const Ateliers = () => {
    return (
        <BackgroundBox>
                <PagesHeader
                    image={workshop}
                    title={'Les ateliers'}
                    description={'Tout pour comprendre le vin, sa complexité et son histoire, découvrez, savourez et gouté le raisin'}
                />
                <CardContainer />
        </BackgroundBox>
    );
}

export default Ateliers;