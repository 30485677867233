import React from 'react';
import { BlackButton } from '../../Styles/Button';
import { CircularProgress, Grid, TextField } from '@mui/material';
import api from '../../Api/api';

export const Authentification = (props) => {
   const { bearer, setBearer } = props;
   const [{ mail, password }, setInfo] = React.useState({ mail: '', password: '' });
   const [isLoading, setIsLoading] = React.useState(false);
 
   const handleChange = itemKey => event => {
     let result = event.target.value
     setInfo(prevState => {
         const states = { ...prevState };
         states[itemKey] = result;
         return states;
     });
   };
 
    const handleValidation = async (mail, password) => {
      setIsLoading(true);
      const adminInfo = { mail, password };
      const token = await api.adminLogin(adminInfo);
      setIsLoading(false);
      if (token.length !== 0) {
        setBearer(token);
        sessionStorage.setItem('token', token);
      }
    };
 
   return (
     <Grid sx={{padding: 10}} container spacing={2}>
       <Grid item xs={12}>
         <h1>Veuillez vous authentifier</h1>
       </Grid>
       <Grid item xs={12}>
         <TextField
           label="Identifiant"
           value={mail}
           onChange={value => handleChange('mail')(value)}
         />
       </Grid>
       <Grid item xs={12}>
         <TextField
           label="Mot de passe"
           type="password"
           value={password}
           onChange={value => handleChange('password')(value)}
         />
       </Grid>
       <Grid item xs={12}>
         <BlackButton
          onClick={() => handleValidation(mail, password)}>
           Se connecter
         </BlackButton>
       </Grid>
       {isLoading &&
        <Grid item xs={12}>
            <CircularProgress color="inherit" />
        </Grid>
        }
     </Grid>
   );
 };

 export default Authentification;