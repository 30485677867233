import { Grid, Box, Typography } from '@mui/material'

export const PagesHeader = (props) => {
   const {title, description, image} = props;

   return (
      <Box
         sx={{
            position: 'relative',
            zIndex: 0,
            backgroundColor: '#9a060d',
            padding: 3,
            marginBottom: 5,
            overflow: 'hidden',
            height: '40vh',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
         }}
      >
         {image && (
            <img
               src={image}
               alt={title}
               style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Remplit la box tout en gardant les proportions
                  zIndex: -1, // Place l'image derrière le contenu
               }}
            />
         )}
         <Box
            container
            sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '100%',
            }}
         >
               <Box
                  sx={{
                     textAlign: 'center',
                     padding: 2,
                     backgroundColor: 'rgba(255, 255, 255, 0.1)',
                     backdropFilter: 'blur(2px)',
                     borderRadius: 2,
                     width: { xs: '100%', sm: '60%' },
                  }}
               >
                     <Typography
                        variant="h2"
                        component="h1"
                        sx={{
                           fontWeight: 'bold',
                           color: '#FBFCFA',
                        }}
                     >
                        {title}
                     </Typography>
                     <Typography
                        variant="body1" // Utilisez un style de texte approprié
                        component="p"
                        sx={{
                           paddingTop: 2,
                           lineHeight: 1.6,
                           color: '#FBFCFA',
                        }}
                     >
                        {description}
                     </Typography>
               </Box>
         </Box>
      </Box>
   );
}   

export default PagesHeader;