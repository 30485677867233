import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import ModalReservation from '../../Components/ModalReservation';
import { BlackButton } from '../../Styles/Button';
import { useSelector } from 'react-redux';
import { totalPrice, totalProduct } from '../../Store/panierReducer';
import { Link } from 'react-router-dom';
import { ModalUnavailable } from '../ModalNonAvailableProduct';

export const TotalCard = () => {

  const priceTotal = useSelector(totalPrice)
  const productTotal = useSelector(totalProduct)

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div>
      <Card 
        sx={{
          maxWidth: 345,
          height: 'fit-content',
          paddingY: 3,
          paddingX: 5,
          borderRadius: 0,
          backgroundColor: '#FBFCFA',
          display: 'flex',
          flexDirection: 'column'
        }}
        elevation={0}
      >
        <CardContent>
            <Typography gutterBottom bold variant="h5" component="h1" 
                sx={{
                    fontSize: 18,
                    textAlign: 'left',
                    fontWeight: 'bold',
                }}>
                Récapitulatif de commande
            </Typography>
            <Divider sx={{ paddingTop: 1, borderBottomWidth: 2 }} variant="fullWidth" orientation="horizontal" flexItem />
            <Grid container alignContent='center' spacing={0}>
              <Grid item xs={8}>
              <Typography gutterBottom variant="h7" component="div" sx={{ paddingTop: 2, textAlign: 'left'}}>
                  Nombre d'articles :
              </Typography>
              </Grid>
              <Grid item xs={4}>
              <Typography gutterBottom variant="h7" component="div" sx={{ paddingTop: 2, paddingRight: 2, textAlign: 'right'}}>
                  {productTotal}
              </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: 1 }} orientation="horizontal" flexItem />
            <Grid container justifyContent='flex-start' spacing={0}>
              <Grid item xs={8}>
              <Typography fontWeight={'bold'} gutterBottom variant="h7" component="div" sx={{paddingTop: 2, textAlign: 'left'}}>
                  Prix total :
              </Typography>
              </Grid>
              <Grid item xs={4}>
              <Typography fontWeight={'bold'} gutterBottom variant="h7" component="div" sx={{paddingTop: 2, paddingRight: 2, textAlign: 'right'}}>
                  {priceTotal} €
              </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ paddingTop: 1 }} orientation="horizontal" flexItem />
        </CardContent>
            <BlackButton
              onClick={() => productTotal ? setIsOpen(true) : setIsOpen(false)}
            >
              Passer au paiement
            </BlackButton>
            <Typography
              component={Link}
              to='/ateliers'
              sx={{
                color: '#16191e'
              }}
            >
              Continuer le shopping
            </Typography>
      </Card>
      <ModalReservation
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}

export default TotalCard