import { createSlice } from '@reduxjs/toolkit';

export const panierReducer = createSlice({
  name: 'panier',
  initialState: localStorage.getItem('panier') ? JSON.parse(localStorage.getItem('panier')) : [],

  reducers: {
    addProduct: (state, action) => {
      const index = state.findIndex(el => el.product._id === action.payload.product._id)
      let newArray = state.slice()
      if (index === -1) {
        const newProduct = {
          ...action.payload,
        }
        newArray.splice(state.length, 0, newProduct)
        localStorage.setItem('panier', JSON.stringify(newArray));
      } else {
        const existingProduct = newArray[index]
        const updatedProduct = {
          ...action.payload,
          quantity: existingProduct.quantity+action.payload.quantity,
        }
        newArray.splice(index, 1, updatedProduct)
        localStorage.setItem('panier', JSON.stringify(newArray));
      }
      return newArray;
    },

    removeProduct: (state, action) => {
      const index = state.findIndex(el => el.product._id === action.payload.product._id)
      let newArray = state.slice()
      newArray.splice(index, 1)
      localStorage.setItem('panier', JSON.stringify(newArray));
      return newArray
    },

    removeAllProduct: (state, action) => {
      let newArray = state.slice()
      newArray.splice(0, state.length)
      console.log('state.length', state.length)
      localStorage.setItem('panier', JSON.stringify(newArray));
      return newArray
    },

    increaseQuantityByOne: (state, action) => {
      let newArray = state.map((item) => {
        if (item.product._id !== action.payload.product._id) {
          return item
        }
        return {
          ...item,
          quantity: action.payload.quantity+1
        }
      })
      localStorage.setItem('panier', JSON.stringify(newArray));
      return newArray
    },

    decreaseQuantityByOne: (state, action) => {
      let newArray = state.map((item) => {
        if (item.product._id !== action.payload.product._id) {
          return item
        }
        return {
          ...item,
          quantity: item.quantity-1
        }
      })
      localStorage.setItem('panier', JSON.stringify(newArray));
      return newArray
    }
  }
});

export const { 
  addProduct,
  removeProduct,
  removeAllProduct,
  changeQuantity,
  increaseQuantityByOne,
  decreaseQuantityByOne
} = panierReducer.actions;

export const selectProducts = (state) => state.panier;

export const totalProduct = (state) => {
  let totalProduct = 0
  state.panier.forEach(element => {
    totalProduct += element.quantity
  });
  return totalProduct
}

export const totalPrice = (state) => {
  let totalPrice = 0
  state.panier.forEach(element => {
    totalPrice += element.product.price*element.quantity
  });
  return totalPrice
}

export default panierReducer.reducer;
