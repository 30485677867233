import { Box, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";


export const SelectFilter = (props) => {

   const {
      whatIsVisible,
      setWhatIsVisible,
      dateAtelier,
      setDateAtelier,
      search,
      setSearch
   } = props

   return (
      <>
         <Grid container width={'100%'}>
            <Box sx={{
               borderRadius: 0,
               width: '100%',
            }}
            >
               <Grid item xs={12}>
                  <Typography sx={{color: '#18191e'}}>
                     Filtrer ce que vous voulez voir :
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Select
                     sx={{
                        width: '20%',
                        backgroundColor: '#FFF',
                        margin: 2,
                        fontWeight: 'bold',
                        fontSize: '15px'
                     }}
                     label="filtrer par ..."
                     value={whatIsVisible}
                     onChange={(e) => setWhatIsVisible(e.target.value)}
                  >
                     <MenuItem value="atelier">Ateliers</MenuItem>
                     <MenuItem value="boutique">Boutiques</MenuItem>
                     <MenuItem value="customer">Clients</MenuItem>
                  </Select>
                  { whatIsVisible === "atelier" &&
                  <Select
                     sx={{
                        width: '10%',
                        backgroundColor: '#FFF',
                        margin: 2,
                        fontSize: '13px'
                     }}
                     label="temporalité"
                     value={dateAtelier}
                     onChange={(e) => setDateAtelier(e.target.value)}
                  >
                     <MenuItem value="past">passé</MenuItem>
                     <MenuItem value="futur">à venir</MenuItem>
                  </Select>
                  }
                  { whatIsVisible === "customer" &&
                  <TextField
                     sx={{
                        backgroundColor: '#FFF',
                        margin: 2,
                        fontSize: '15px'
                     }}
                     label="Rechercher un client"
                     value={search}
                     onChange={(e) => setSearch(e.target.value)}
                  />
                  }
               </Grid>
            </Box>
         </Grid>
      </>
   )
}

export default SelectFilter;