import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, IconButton, Typography } from '@mui/material';
import { Add, Delete, Remove } from '@mui/icons-material';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { selectProducts } from '../../Store/panierReducer';
import { removeProduct } from '../../Store/panierReducer';
import { increaseQuantityByOne } from '../../Store/panierReducer';
import { decreaseQuantityByOne } from '../../Store/panierReducer';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FBFCFA',
    color: '#18191e',
    fontWeight: 'bold',
    fontSize: 18
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#FBFCFA',
    border: 10,
}));

const QuantityCell = ({product}) => {

  const dispatch = useDispatch()

  const handleAdd = (product) => {
    if (product.quantity < product.product.totalUnits - product.product.bookedUnits) {
      dispatch(increaseQuantityByOne(product))
    }
  };
  const handleRemove = (product) => {
    if (product.quantity > 1) {
      dispatch(decreaseQuantityByOne(product))
    }
  };

  return (
    <Grid container>
      <Grid item xs="4">
          <IconButton edge="start" color="default" onClick={() => handleRemove(product)}>
              <Remove />
          </IconButton>
      </Grid>
      <Grid item xs="4">
      <Typography sx={{paddingTop: 1}}>{product.quantity}</Typography>
      </Grid>
      <Grid item xs="4">
          <IconButton edge="end" color="default" onClick={() => handleAdd(product)}>
              <Add />
          </IconButton>
      </Grid>
    </Grid>
  )
}

export const Cart = () => {

  const dispatch = useDispatch()
  const cart = useSelector(selectProducts)

  const handleDelete = (product) => {
    dispatch(removeProduct(product))
  }

  return (
    cart.length ?
      <TableContainer component={Paper} 
        sx={{
          minWidth: { xs: 'fit-content', md: '100%' },
          borderRadius: 0,
        }}
        elevation={0}
      >
        <Table sx={{ minWidth: 0 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Article</StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{display: {xs: 'none', md:'table-cell'}}}
              >
                Prix unitaire
              </StyledTableCell>
              <StyledTableCell align="center">Quantité</StyledTableCell>
              <StyledTableCell align="center">Prix total</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.map((row) => (
              <StyledTableRow key={row.title}>
                <StyledTableCell align="center" component="th" scope="row">
                  <Typography>
                    {row.product.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#9e9e9e',
                    }}
                  >
                    {row.product.subtitle}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{display: {xs: 'none', md:'table-cell'}}}
                >
                  {row.product.price} €
                </StyledTableCell>
                <StyledTableCell align="center">
                  <QuantityCell product={row}/>
                </StyledTableCell>
                <StyledTableCell align="center">{row.product.price*row.quantity} €</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() => handleDelete(row)}>
                      <Delete/>
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      :
      <div/>
  );
}

export default Cart
