import './App.css';
import React from 'react';
import ModalReservation from './Components/ModalReservation';
import Navbar from './Components/Navbar';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import Ateliers from './Pages/Ateliers';
import Panier from './Pages/Panier';
import Boutique from './Pages/Boutique'
import Backoffice from './Pages/Backoffice';
import Contact from './Pages/Contact';
import Terms from './Pages/Terms';
import { Provider } from 'react-redux'
import store from './Store/store'
import Payement from './Pages/Payement';
import Footer from './Components/Footer';
import { Helmet } from 'react-helmet';


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Navbar/>
          <Helmet>
            {window.location.pathname === '/backoffice' && (
              <meta name="robots" content="noindex, nofollow" />
            )}
          </Helmet>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/ateliers' element={<Ateliers/>}/>
            <Route path='/boutique' element={<Boutique/>}/>
            <Route path='/panier' element={<Panier/>}/>
            <Route path='/backoffice' element={<Backoffice/>}/>
            <Route path='/payement' element={<Payement/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/terms' element={<Terms/>}/>
          </Routes>
        </div>
        <footer>
          <Footer/>
        </footer>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
