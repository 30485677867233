import React from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography, 
    styled,
    ListItemButton,
    ListItemText,
    Icon,
    IconButton,
    Badge
} from '@mui/material';
import DrawerItem from '../DrawerItem';
import { Link } from 'react-router-dom';  // Correction ici
import { ShoppingCart } from '@mui/icons-material';
import {StyledBadge} from '../../Styles/Badge';
import { useSelector } from 'react-redux'
import { selectProducts } from '../../Store/panierReducer';

const StyledToolbar = styled(Toolbar) ({
    width: '100%',
    display: 'flex',
    position: 'relative',
    margin: '0 auto',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0 auto',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    transition: '0.3s ease-out',
    backgroundColor: '#F1F2F6',
    color: 'white',
    '&.mask': {
        top: '150px',
        maskImage: 'linear-gradient(black 70%, transparent 100%)',
    },
    '.invert-text': {
        filter: 'invert(100%)'
    }
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")] : {
        display: "flex",
    },
}));

const itemList = [
    {
    text: "Accueil",
    to: "/"
    },
    {
    text: "Ateliers",
    to: "/ateliers"
    },
    {
    text: "Boutique",
    to: "/boutique"
    },
    {
    text: "Contact",
    to: "/contact"
    }
];

export const Navbar = () => {

    const panierLength = useSelector(selectProducts).length

    return (
        <AppBar 
            component="nav" 
            position="sticky"
            sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                padding: 0,
                margin: 0,
            }}
            elevation={0}
        >
            <StyledToolbar sx={{display: 'flex'}}>
            <Box sx={{textAlign: 'center'}}>
                <Typography 
                    variant="h6"
                    component={Link}
                    to='/'
                    sx={{
                        textDecoration: 'none',
                        color: '#121212',
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    Le Chai
                </Typography>
            </Box>
            <Box>
            <ListMenu>
                {itemList.map(item => {
                    const { text } = item;
                    return (
                        <ListItem 
                            key={text}
                            sx={{
                                background: 'transparent',
                                ':hover': {
                                    background: 'transparent'
                                }
                            }}
                        >
                            <ListItemButton
                            component={Link}
                            to={item.to}
                            sx={{
                                color: '#121212',
                                position: 'relative', // Position relative pour que la Box soit positionnée par rapport à ce conteneur
                                display: 'flex', // Utiliser Flexbox pour le centrage
                                justifyContent: 'center', // Centre horizontalement
                                alignItems: 'center', // Centre verticalement
                                textAlign: 'center',
                                zIndex: 2,
                                /* padding: '10px', */ // Assurez-vous que le padding est approprié pour le bouton
                                overflow: 'hidden',
                                ':hover': {
                                background: 'transparent',
                                },
                                ':hover .nav-bg': {
                                transform: 'scale(2)',
                                },
                            }}
                            >
                            <Box
                                className="nav-bg"
                                sx={{
                                bottom: 10,
                                overflow: 'hidden',
                                height: '0.5px', // Ajusté pour remplir la hauteur du bouton
                                width: '100%', // Ajusté pour remplir la largeur du bouton
                                backgroundColor: '#a4161a',
                                zIndex: 1, // Assurez-vous qu'il est sous le texte
                                transition: 'transform 1s ease',
                                transform: 'scale(0)',
                                position: 'absolute', // Position absolute pour être centré dans le bouton
                                }}
                            />
                            <ListItemText sx={{ zIndex: 2 }} primary={text} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </ListMenu>
            </Box>
            <Box 
                sx={{
                    display: 'flex',
                }}
            >
                <IconButton component={Link} to='/panier'
                    sx={{
                        color: '#121212',
                        "&:hover":{
                            backgroundColor: 'transparent',
                            color: '#121212',
                        }
                    }}
                >
                    <StyledBadge badgeContent={panierLength}>
                        <ShoppingCart/>
                    </StyledBadge>
                </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <DrawerItem /> 
            </Box>
        </StyledToolbar>
    </AppBar>
    )
}

export default Navbar;