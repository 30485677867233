import { Box, Grid, Typography } from "@mui/material"
import { TextBox } from "../Styles/Box"
import Cart from "../Components/Cart"
import TotalCard from "../Components/TotalCard"
import { BackgroundBox } from "../Styles/Background"
import PagesHeader from "../Components/PagesHeader"


export const Panier = () => {
    return (
    <BackgroundBox>
        <PagesHeader
            title={"Votre panier"}
            description={"Il ne vous reste plus que quelques clics pour finaliser votre commande et passer au paiement !"}
        />
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: {xs: 'center', md: 'start'},
            justifyContent: 'center', 
        }}
    >
        <Box
            sx={{
                width: { xs: '90%', md: '65%' },
                paddingTop: { xs: 2, md: 0 },
                paddingX: 2,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Cart/>
        </Box>
        <Box
            sx={{
                minWidth: 'fit-content',
                maxWidth: '100%',
                paddingX: 2,
                paddingTop: {xs: 5, md: 0},
                paddingBottom : {xs: 8, md: 0},

            }}
        >
            <TotalCard/>
        </Box>
    </Box>
    </BackgroundBox>
    )
}

export default Panier