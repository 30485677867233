import axios from 'axios';

const API = axios.create({
  baseURL: 'https://jemenfoucomplet.fr/api/',
  // baseURL: 'http://localhost:3003/api/',
  timeout: 1200000
});

const api = {

    getAllProduit: async () => {
        try {
        const { data } = await API.get(`/produit`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getAllProduitByType: async (type) => {
        try {
        const { data } = await API.get(`/produit?type=${type}`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getProduit: async (productId) => {
        try {
        const { data } = await API.get(`/produit/${productId}`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    createProduit: async (product, token) => {
        try {
        const { data } = await API.post(`/produit`, product, {
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    updateProduit: async (productId, product) => {
        try {
        const { data } = await API.put(`/produit/${productId}`, product);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    handleBuyerToProduit: async (productId, newBuyerId, wantedQuantity, action) => {
        try {
        const payload = {
            "buyers" : newBuyerId,
            "quantity" : wantedQuantity,
            "action" : action
        }
        const { data } = await API.put(`/produit/handleBuyer/${productId}`, payload);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    deleteProduit: async (productId, token) => {
        try {
        const { data } = await API.delete(`/produit/${productId}`, {
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getAllOrder: async () => {
        try {
        const { data } = await API.get(`/order`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getOrder:  async (orderId) => {
        try {
        const { data } = await API.get(`/order/${orderId}`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    createOrder: async (order) => {
        try {
        const { data } = await API.post(`/order`, order);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getAllCustomer:  async () => {
        try {
        const { data } = await API.get(`/customer`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    updateOrder: async (orderId, order) => {
        try {
        const { data } = await API.put(`/order/${orderId}`, order);
        return data;    
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getCustomer: async (customerId) => {
        try {
        const { data } = await API.get(`/customer/${customerId}`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    getCustomerByMail: async (customerMail) => {
        try {
        const { data } = await API.get(`/customer?mail=${customerMail}`);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    createCustomer: async (customer) => {
        try {
        const { data } = await API.post(`/customer`, customer);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    updateCustomer: async (customerId, customer) => {
        try {
        const { data } = await API.put(`/customer/${customerId}`, customer);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    handleOrderToCustomer: async (customerId, orderId) => {
        try {
        const { data } = await API.put(`/customer/addOrder/${customerId}`, {orderId});
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    createCheckoutSession: async (customerInfo, stripeCart) => {
        try {
        const response = await API.post(`/payement/create-checkout-session/${customerInfo}`, stripeCart);
        const data = response.data;
        return data
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    closeCheckoutSession: async (sessionId) => {
        try {
        const { data } = await API.put(`/payement/close-checkout-session/${sessionId}`);
        return data
        } catch (err) {
        console.error(err);
        return [];
        }
    },
    getCheckoutSession: async (sessionId) => {
        try {
        const response = await API.get(`/payement/${sessionId}`);
        const session = response.data;
        return session
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    adminLogin: async (adminInfo, res) => {
        try {
        const response = await API.post(`/admin`, adminInfo);
        const { token } = response.data;
        return token
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    checkToken: async (bearer) => {
        try {
        const { data } = await API.get(`/admin/validateToken`, {
            headers: {
            Authorization: `Bearer ${bearer}`
            }
        });
        return data.valid;
        } catch (err) {
        console.error(err);
        return [];
        }
    },

    sendConfirmationMail: async (payload) => {
        try {
        const { data } = await API.post(`/mail`, payload);
        return data;
        } catch (err) {
        console.error(err);
        return [];
        }
    },
}

export default api;
