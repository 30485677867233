import ModalCreation from '../../Components/ModalCreation'
import Authentification from '../../Components/Authentification'
import React from 'react'
import { BlackButton } from '../../Styles/Button'
import AtelierTable from '../../Components/AtelierTable'
import { Card, Grid, TextField, Typography } from '@mui/material'
import api from '../../Api/api'
import SelectFilter from '../../Components/SelectFilter'
import PagesHeader from '../../Components/PagesHeader'

export default function BackofficeContainer() {

   const [isOpen, setIsOpen]= React.useState(false)
   const [bearer, setBearer] = React.useState(sessionStorage.getItem('token'))
   const [isValid, setIsValid] = React.useState(false)

   const [whatIsVisible, setWhatIsVisible] = React.useState('atelier');
   const [dateAtelier, setDateAtelier] = React.useState('futur');
   const [search, setSearch] = React.useState(''); 

   const isBearerValid = async () => {
     const check = await api.checkToken(bearer);
     if (check === true) {
         setIsValid(true);
      } else {
       setBearer('');
       sessionStorage.removeItem('token');
       setIsValid(false);
      }
   }

   React.useEffect(() => {
       isBearerValid();
   }, [bearer]);

   return (
       
     <>
       {!isValid ?
         <Authentification bearer={bearer} setBearer={setBearer} />
         :
         <>  
           <PagesHeader
               title={"Espace administrateur"}
               description={"Vous pouvez visualiser, les réservation sur vos ateliers, le nombre de vente de chacun de vos produits ainsi qu'accéder aux informations sur vos clients. Vous pouvez aussi créer et ajouter de nouveax ateliers ou produit à votre boutique"}
            />
           <Grid 
              container
              sx={{
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column',
              }}
           >
               <Grid item xs={12}>
                  <BlackButton
                     sx={{
                        height: '80%'
                     }}
                     onClick={() => setIsOpen(true)}
                  >
                     Créer un produit
                  </BlackButton>
               </Grid>
               <Grid item xs={12} width={"100%"}>
                  <SelectFilter
                     whatIsVisible={whatIsVisible}
                     setWhatIsVisible={setWhatIsVisible}
                     dateAtelier={dateAtelier}
                     setDateAtelier={setDateAtelier}
                     search={search}
                     setSearch={setSearch}
                  />
               </Grid>
               <Grid item xs={12}>
                  <AtelierTable
                     whatIsVisible={whatIsVisible}
                     dateAtelier={dateAtelier}
                     search={search}
                  />
               </Grid>
            </Grid>
           <ModalCreation isOpen={isOpen} setIsOpen={setIsOpen}></ModalCreation>
       </>}
     </>
   )
};
