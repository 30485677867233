import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import api from '../../Api/api';
import { Create, Delete } from '@mui/icons-material';
import ModalCreation from '../ModalCreation'

const Row = (props) => {
  const { data, onUpdate, whatIsVisible } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [customers, setCustomers] = React.useState([])
  const [orders, setOrders] = React.useState([])
  const token = sessionStorage.getItem('token');

  const handleDelete = async (productId) => {
    await api.deleteProduit(productId, token);
    onUpdate();
  }

  const fetchBuyers = async (data) => {
    const buyers = []
    if (data?.buyers && data?.buyers.length > 0) {
      for (const customerId of data.buyers) {
        const buyer = await api.getCustomer(customerId);
        if (buyer !== null) {
          buyers.push(buyer);
        }
      }
    }
    return buyers;
  }

  const fetchOrders = async (data) => {
    const allProductBought = []
    if (data?.orders) {
      for (const orderId of data.orders) {
        const order = await api.getOrder(orderId);
        if (order !== null) {
          for (const product of order.products) {
            const detailedProduct = await api.getProduit(product.produitId);
            const alreadyStocked = allProductBought.findIndex(
              (p) => p.title === detailedProduct.title
            );
            if (detailedProduct !== null) {
              if (alreadyStocked === -1) {
                allProductBought.push({
                  _id: detailedProduct._id,
                  title: detailedProduct.title,
                  price: detailedProduct.price,
                  quantity: product.quantity
                });
              } else {
                allProductBought[alreadyStocked].quantity += product.quantity;
              }
            }
          }
        }
      }
    }
    return allProductBought;
  }
  if (whatIsVisible === 'customer') {
    fetchOrders(data);
  }

  React.useEffect(() => {
    if (whatIsVisible !== 'customer') {
      fetchBuyers(data).then((buyers) => setCustomers(buyers));
    } else {
      fetchOrders(data).then((orders) => setOrders(orders));
    }
  }, []);

  return (
    <>
    <ModalCreation
      isOpen={isOpen}
      setIsOpen={(state) => {setIsOpen(state); onUpdate();}}
      produit={data}
    />
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {whatIsVisible !== 'customer'
        ?
        <>
        <TableCell>
          {customers.length > 0 && <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        <TableCell component="th" scope="data">{data?.title}</TableCell>
        <TableCell align="left">{data?.date}</TableCell>
        <TableCell align="left">{data?.place}</TableCell>
        <TableCell align="left">{data?.bookedUnits} / {data?.totalUnits}</TableCell>
        <TableCell align="left">
          <IconButton onClick={() => { setIsOpen(true); onUpdate(); }}>
            <Create />
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <IconButton onClick={() => handleDelete(data?._id)}>
            <Delete />
          </IconButton>
        </TableCell>
        </>
        :
        <>
        <TableCell>
          {orders.length > 0 && <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        <TableCell component="th" scope="data">{data?.name}</TableCell>
        <TableCell align="left">{data?.surname}</TableCell>
        <TableCell align="left">{data?.phone}</TableCell>
        <TableCell align="left">{data?.mail}</TableCell>
        </>
        }
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                  <TableCell/>
                    {whatIsVisible !== 'customer'
                    ?
                    <>
                    <TableCell sx={{ fontWeight: 'bold' }}>Prénom</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Nom</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Tél.</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Mail</TableCell>
                    </>
                    :
                    <>
                    <TableCell sx={{ fontWeight: 'bold' }}>Titre</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Prix</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Quantité</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                    </>
                    }
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {whatIsVisible !== 'customer'
                    ?
                    <>
                    {customers?.map((customer) => (
                      <TableRow key={customer?._id}>
                        <TableCell/>
                        <TableCell component="th" scope="data">{customer?.name}</TableCell>
                        <TableCell>{customer?.surname}</TableCell>
                        <TableCell>{customer?.phone}</TableCell>
                        <TableCell>{customer?.mail}</TableCell>
                      </TableRow>
                    ))}
                    </>
                    :
                    <>
                    {orders?.map((order) => (
                      <TableRow key={order?._id}>
                        <TableCell/>
                        <TableCell component="th" scope="data">{order?.title}</TableCell>
                        <TableCell>{order?.price} €</TableCell>
                        <TableCell>{order?.quantity}</TableCell>
                        <TableCell>{order?.quantity * order?.price} €</TableCell>
                      </TableRow>
                    ))}
                    </>
                    }
                  </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment></>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FBFCFA',
    color: '#18191e',
    fontWeight: 'bold',
    fontSize: 18
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#FBFCFA',
    border: 10,
}));

export const AtelierTable = (props) => {
  const { whatIsVisible, dateAtelier, search} = props
  const [data, setData] = React.useState([]);
  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
      const fetchData = async () => {
        let listData = [];
          if (whatIsVisible === 'customer') {
            listData = await api.getAllCustomer();
            if (search != '') {
              listData = listData.filter((el) =>
                el.name.startsWith(search) ||
                el.surname.startsWith(search) ||
                el.mail.startsWith(search) ||
                el.phone.startsWith(search)
              );
            }
          } else {
            listData = await api.getAllProduitByType(whatIsVisible);
            if (whatIsVisible === 'atelier') {
              listData = listData.filter((el) => {
                const [datePart, timePart] = el.date.split(' ');
                const [day, month, year] = datePart.split('/').map(Number);
                const [hour, minute] = timePart ? timePart.split(':').map(Number) : [0, 0];
                const dateThisAtelier = new Date(year, month - 1, day, hour, minute);
                const today = new Date();
                if (dateAtelier === 'futur') {
                  return dateThisAtelier >= today
                } else {
                  return dateThisAtelier < today
                }
              })
            }
          }
          setData(listData);
      };
      fetchData();
  }, [update, dateAtelier, whatIsVisible, search]);

  return (
    <TableContainer sx={{maxWidth: '100%', marginTop: 2, marginBottom: 8}} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            {whatIsVisible !== 'customer'
            ?
            <>
            <StyledTableCell align="left">Titre de l'atelier</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left">Lieu</StyledTableCell>
            <StyledTableCell align="left">Nombre acheté</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
            </>
            :
            <>
            <StyledTableCell align="left">Prénom</StyledTableCell>
            <StyledTableCell align="left">Nom</StyledTableCell>
            <StyledTableCell align="left">Tél</StyledTableCell>
            <StyledTableCell align="left">Mail</StyledTableCell>
            </>
            }
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map((data) => (
            <Row
              key={data?._id}
              data={data}
              whatIsVisible={whatIsVisible}
              onUpdate={() => setUpdate(prev => !prev)}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AtelierTable;