import React, { FC, ReactElement } from "react";
import { Box, Container, Divider, Grid, IconButton, List, ListItemButton, Typography } from "@mui/material";
import { Facebook, Instagram, Mail } from "@mui/icons-material";
import { Link } from 'react-router-dom';

export const Footer = () => {

   const itemList = [
      {
      text: "A propos",
      to: "/about"
      },
      {
      text: "Contact",
      to: "/contact"
      },
      {
      text: "Termes et conditions",
      to: "/terms"
      },
   ];

  return (
    <Box
      sx={{
            backgroundColor: "#9a060d",
            width: "100%",
         }}
    >
   <Box
      sx={{
         width: "100%",
         display: "flex",
         textAlign: "center", // Centrer le texte
         alignItems: "center", // Centrer verticalement
         justifyContent: "space-evenly", // Centrer horizontalement
         paddingY: 1,
         flexDirection: {xs: "column", md: "row"},
      }}
   >
      <Box>
         <Typography color="#FBFCFA" variant="h5" sx={{textDecoration: {xs: 'underline', md: 'none'}}}>
            Le Chai
         </Typography>
      </Box>
      <Box>
         <List
            sx={{
               display: "flex",
               flexDirection: {xs: "column", md: "row"},
               alignItems: "center",
               justifyContent: "space-evenly",
               gap: {xs: 0, md: 1},
            }}
         >
            {itemList.map((item, index) => (
               <ListItemButton
                  key={index}
                  component={Link}
                  to={item.to}
                  sx={{
                     textAlign: "center",
                     justifyContent: "center",
                     color: "#FBFCFA",
                     width: "auto", // Permet au bouton de s'adapter au contenu
                     ":hover": {
                        color: "#F1F2F6",
                     },
                  }}
                  variant="subtitle1"
                  >
                  {item.text}
               </ListItemButton>
            ))}
         </List>
      </Box>
      <Box>
         <List
            sx={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "center",
               alignItems: "center",
               textAlign: "center",
               width: '100%',
               gap: 1,
            }}
         >
            <IconButton
               href="https://www.instagram.com/sampec57/?__d=1%2F"
               target="_blank" // Ouvre le lien dans un nouvel onglet
               rel="noopener noreferrer" // Améliore la sécurité lors de l'ouverture dans un nouvel onglet
               sx={{
                  color: '#FBFCFA', // Définit la couleur de l'icône en blanc
               }}
               >
               <Instagram />
            </IconButton>
            <IconButton
               href="https://www.facebook.com/LeChaiMetz/"
               target="_blank" // Ouvre le lien dans un nouvel onglet
               rel="noopener noreferrer" // Améliore la sécurité lors de l'ouverture dans un nouvel onglet 
               sx={{
                  color: '#FBFCFA', // Définit la couleur de l'icône en blanc
               }}
            >
               <Facebook/>
            </IconButton>
            <IconButton
               sx={{
                  color: '#FBFCFA', // Définit la couleur de l'icône en blanc
               }}
            >
               <Mail/>
            </IconButton>
         </List>
         </Box>
   </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Divider
          orientation="horizontal"
          sx={{
            backgroundColor: "#FBFCFA",
            width: '90%', // Ajustez la largeur du Divider comme nécessaire
          }}
        />
      </Box>
   <Box
      sx={{
         width: "100%",
         display: "flex",
         textAlign: "center", // Centrer le texte
         alignItems: "center", // Centrer verticalement
         justifyContent: "space-between", // Centrer horizontalement
         flexDirection: {xs: "column", md: "row"},
         paddingTop: 1,
         paddingBottom: 3
      }}
   >
      <Box>
         <Typography
            variant="subtitle"
            sx={{
               color: "#C9C6C6",
               fontSize: 12,
               paddingLeft: {xs: 0, md:5},
            }} 
         >
            {`© Copyright 2024, All Rights Reserved`}   
         </Typography>
      </Box>
      <Box>
         <Typography
            variant="subtitle"
            sx={{
               color: "#C9C6C6",
               fontSize: 12,
               paddingRight: {xs: 0, md:5},
            }} 
         >
            {`${new Date().getFullYear()} | React | Material UI | React Router`}
         </Typography>
      </Box>
   </Box>
   </Box>
  );
};

export default Footer;