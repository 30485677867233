import { Card, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { BlackButton, OutlinedBlackButton } from '../../Styles/Button';
import { ClassicModal } from '../../Styles/Modal';
import { Link } from 'react-router-dom';

export const ModalMajeur = (props) => {

    const { isOpen, setIsOpen } = props
        
    const [isContinue, setIsContinue] = React.useState(false);

    const handleClick = () => {
        setIsContinue(isContinue)
        sessionStorage.setItem("isModalAccepted", "true")
        setIsOpen(false)
    };

    return(
        <div>
        <ClassicModal 
            sx={{
                margin: "auto",
                height: "fit-content",
                alignContent: "center",
                overflowY: "auto",
                display: "flex",
            }}
            open={isOpen}
        >
                <Card id="modal" display="flex">
                    <Grid
                        display="flex"
                        container
                        padding={5}
                        rowSpacing={2}
                        columnSpacing={1}
                        direction="row"
                        justifyContent="center" // Centre horizontalement
                        alignItems="center"      // Centre verticalement
                        textAlign="center"
                    >   
                        <Grid item xs="12">
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "30px",
                                fontWeight: "bold",
                            }}>
                                Etes vous majeur ?
                            </Typography>
                            <Divider sx={{paddingTop: 1, borderBottomWidth: 2}} variant="fullWidth" orientation="horizontal" flexItem/>
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "20px",
                                paddingTop: 3
                            }}>
                                La vente des boissons alcooliques à des mineurs est interdite. 
                                En cliquant sur continuer, je confirme avoir plus de 18 ans.
                            </Typography>
                        </Grid>
                        <Grid item sm="12" md="6">
                            <BlackButton variant='outlined' onClick={handleClick}>Continuer</BlackButton>
                        </Grid>
                        <Grid item sm="12" md="6">
                            <OutlinedBlackButton 
                                variant='outlined'
                                sx={{
                                    margin: 0
                                }}
                                onClick={() => {
                                    window.history.go(-1);
                                    return false
                                }}
                            >
                                Quitter
                            </OutlinedBlackButton>
                        </Grid>
                    </Grid>
                </Card>
        </ClassicModal>
        </div>
    )
}

export default ModalMajeur