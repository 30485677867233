import ModalCreation from '../Components/ModalCreation'
import React from 'react'
import { BlackButton } from '../Styles/Button'
import AtelierTable from '../Components/AtelierTable'
import { Card, Grid, TextField, Typography } from '@mui/material'
import api from '../Api/api'
import BackofficeContainer from '../Containers/BackOfficeContainer'

export const Backoffice = () => {

    return (
        
      <>
        <BackofficeContainer />
      </>

    )
}

export default Backoffice
