import { Box, Typography } from "@mui/material"
import { BackgroundBox } from "../Styles/Background"
import BoxText from "../Components/BoxText";
import PagesHeader from "../Components/PagesHeader";


export const Contact = () => {
   return (
      <BackgroundBox>
         <PagesHeader
            title={"Contacts"}
            description={"Comment nous joindre et où nous trouver pour toute demande ou question sur nos produits et ateliers"}
         />
         <BoxText
            title='Contact'
            body={<>
               Pour toute demande ou question, n'hésitez pas à nous contacter :
               <br /><strong>par mail :</strong> contact@lechai-metz.fr
               <br /><strong>par téléphone :</strong> +33 6 82 45 53 21
               <br /><strong>sur Instagram :</strong> @sampec57
               <br /><strong>sur Facebook :</strong> @LeChaiMetz
               <br /><br />
               N'hésitez pas à nous rendre visite au Chai à l'adresse suivante :
               <br /> 332 rue Lieutenant-colonel Dagnaux, 57685 Augny
               </>}
            link='https://www.instagram.com/sampec57/?__d=1%2F'
            textButton="Nos réseaux sociaux"
         />
      </BackgroundBox>
   )
};

export default Contact;