import { styled } from '@mui/material/styles';
import Box from '@mui/material/Button';

export const TextBox = styled(Box)(({ theme }) => ({
  margin: 20,
  paddingTop: 50,
  display: 'flex',
  backgroundColor: 'transparent',
  textTransform: 'none',
  alignContent: 'center',
  flex: 1,
  pointerEvents: 'none',
  flexDirection: 'column',
  paddingLeft: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    flex: 2,
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const RightTextBox = styled(Box)(({ theme }) => ({
    margin: 20,
    padding: 30,
    display: 'flex',
    textTransform: 'none',
    textAlign: 'center',
    alignItems: 'center', // Alignement vertical à droite
    justifyContent: 'flex-end', // Alignement horizontal à droite
    flex: 1,
    backgroundColor: '#ffffff',
    pointerEvents: 'auto', // Permettre les interactions de l'utilisateur
    flexDirection: 'column',
    paddingLeft: theme.spacing(80),
    [theme.breakpoints.down('md')]: {
      flex: 2,
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

export const WhiteBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
  margin: 5,
  backgroundColor: '#FBFCFA',
}));

export const PhotoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#9a060d',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2),
  width: {xs: '95%', md: '50%'},
  height: {xs: '100%', md: '80%'},
  overflow: 'hidden',
  borderRadius: 0,
}));
  




