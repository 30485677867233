import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const BackgroundBox = styled(Box)(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   width: '100%',
   minHeight: '100vh',
   paddingBottom: 20,
   background: 'linear-gradient(to bottom, #F1F2F6, #C9C6C6)',
   zIndex: 0, // Assure que le fond est derrière les autres éléments
}));
