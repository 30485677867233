import { Box, Button, Card, Divider, Grid, IconButton, InputAdornment, Modal, Typography } from '@mui/material';
import * as React from 'react';
import  { TextField } from '@mui/material';
import { BlackButton, OutlinedBlackButton, SecondaryButton } from '../../Styles/Button';
import { ClassicModal } from '../../Styles/Modal';
import { Add, Close, Remove } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { addProduct, selectProducts } from '../../Store/panierReducer'

export const ModalNumber = (props) => {

    const dispatch = useDispatch();
    const cart = useSelector(selectProducts)

    const { isOpen, setIsOpen } = props
    const { product } = props
    
    const [number, setNumber] = React.useState(1);
    
    const [isError, setIsError] = React.useState(false)

    const availableUnits = product.totalUnits - product.bookedUnits;

    const handleAdd = () => {
        setNumber(prev => {
            const newValue = parseInt(prev, 10) + 1;
            return newValue <= availableUnits ? newValue : prev;
        });
    };

    const handleRemove = () => {
        setNumber(prev => {
            const newValue = parseInt(prev, 10) - 1;
            return newValue >= 1 ? newValue: prev;
        });
    };

    const handleClick = () => {
        if (numberError(number)) {
            setIsError(true)
        }
        else {
            setIsError(false)
            dispatch(addProduct({product, quantity: parseInt(number, 10)}))
            setIsOpen(false)
        }
    };

    let numberError = (number) => !(/^(?:[1-9]|[1-9]\d)$/).test(number) || number < 1 || number > availableUnits;

    return(
        <div>
        <ClassicModal sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            margin: "auto",
            alignContent: "center",
            }}
            display="flex"
            open={isOpen}
            onClose={(e, reason) => (reason && reason === "backdropClick") ? setIsOpen(!isOpen) : null}>
                <Card id="modal" display="flex" width="fit-content" height="fit-content" position="relative">
                    <IconButton
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                        onClick={() => setIsOpen(false)}
                    >
                        <Close/>
                    </IconButton>
                    <Grid
                        display="flex"
                        container
                        padding={5}
                        rowSpacing={1}
                        columnSpacing={1}
                        direction="row"
                        margin="center"
                        justifyItems="center"
                        textAlign="center"
                    >   
                        <Grid item xs="12" md="8">
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "30px",
                                fontWeight: "bold",
                            }}>
                                {product.title}
                            </Typography>
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "18px",
                                paddingTop: 1
                            }}>
                                {product.subtitle}
                            </Typography>
                        </Grid>
                        <Grid item xs="12">
                            <Divider sx={{paddingTop: 1, borderBottomWidth: 2}} variant="fullWidth" orientation="horizontal" flexItem/>
                            <Typography sx = {{
                                textAlign: "left",
                                fontSize: "14px",
                                paddingTop: 2
                            }}>
                                {product.description}
                            </Typography>
                        </Grid> 
                        <Grid item xs="12">
                            <TextField
                                sx={{
                                    marginTop: 2,
                                    textAlign:'center',
                                    '& .MuiOutlinedInput-root': {
                                        height: 50,
                                    }
                                }}
                                id="number-textField"
                                label={product.type === 'atelier' ? "Nombre de place" : "Nombre d'unités"}
                                placeholder="ex: 5"
                                variant="outlined"
                                required
                                error={numberError(number) && isError}
                                helperText={(numberError(number) && isError) ? `Rentrer un chiffre entre 1 et ${availableUnits}` : null}
                                value={number}
                                onChange={e => setNumber(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            <InputAdornment position="start">
                                                <IconButton edge="start" color="default" onClick={handleRemove}>
                                                    <Remove />
                                                </IconButton>
                                            </InputAdornment>
                                            <InputAdornment position="end">
                                                <IconButton edge="end" color="default" onClick={handleAdd}>
                                                    <Add />
                                                </IconButton>
                                            </InputAdornment>
                                        </>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs="12">
                            <Typography
                                sx={{
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    color: "#d32f2f",
                                }}
                            >
                                Il reste {availableUnits} places disponibles
                            </Typography>
                        </Grid>
                        <Grid item xs="12">
                            <BlackButton
                                variant='outlined'
                                onClick={handleClick}
                                sx={{
                                    marginBottom: 1, 
                                    marginTop: 1
                                }}
                            >
                                Ajouter au Panier
                            </BlackButton>
                        </Grid>
                        <Grid item xs="12">
                            <Typography
                                component={Link}
                                to='/panier'
                                sx={{
                                color: '#16191e'
                                }}
                                onClick={handleClick}
                            >
                                Ajouter et aller au paiement
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
        </ClassicModal>
        </div>
    )
}

export default ModalNumber