import { Box, Typography } from "@mui/material"
import { BackgroundBox } from "../Styles/Background"
import BoxText from "../Components/BoxText";


export const Terms = () => {
   return (
      <BackgroundBox>
         <BoxText
            title='Termes et conditions'
            body={<>
               termes et conditions
               </>}
            link='https://www.instagram.com/sampec57/?__d=1%2F'
            textButton="Plus d'informations"
         />
      </BackgroundBox>
   )
};

export default Terms;