import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const BlackButton = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    margin: theme.spacing(2),  // 'my' en camelCase est 'marginY' pour MUI
    paddingTop: theme.spacing(1.5), // Manually setting padding-top
    paddingBottom: theme.spacing(1.5), // Manually setting padding-bottom
    paddingLeft: 25,
    paddingRight: 25,
    height: 'fit-content',
    fontSize: '12px',
    borderRadius: 0,
    borderColor: '#16191e',
    color: '#fff',
    backgroundColor: '#16191e',
    "&&:hover": {
        backgroundColor: "#424242",
        borderColor: "#424242",
        color: "#fff",
        fontWeight: 'bold',
    },
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    margin: theme.spacing(2),  // 'my' en camelCase est 'marginY' pour MUI
    paddingTop: theme.spacing(1.5), // Manually setting padding-top
    paddingBottom: theme.spacing(1.5), // Manually setting padding-bottom
    paddingLeft: 25,
    paddingRight: 25,
    color: '#16191e',
    fontWeight: 'bold',
    fontSize: '12px',
    borderRadius: 0,
    borderColor: '#16191e',
    border: '1px solid #16191e',
    height: 'fit-content',
    backgroundColor: 'transparent', // Fond transparent par défaut
    "&&:hover": {
        color: '#16191e',
        borderColor: '#16191e',
        backgroundColor: '#eeeeee', // Fond grisé au survol
        fontWeight: 'bold',
    },
    "&&:focus": {
        color: '#16191e',
        borderColor: '#16191e',
        backgroundColor: '#eeeeee', // Fond grisé au focus aussi
        fontWeight: 'bold',
    }
}));

export const OutlinedWhiteButton = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    margin: theme.spacing(2),  // 'my' en camelCase est 'marginY' pour MUI
    paddingTop: theme.spacing(1.5), // Manually setting padding-top
    paddingBottom: theme.spacing(1.5), // Manually setting padding-bottom
    fontSize: '12px',
    borderRadius: 0,
    height: 'fit-content',
    color: '#FBFCFA',
    backgroundColor: 'transparent',
    borderColor: '#FBFCFA',
    "&&:hover": {
        color: '#9a060d',
        borderColor: '#FBFCFA',
        backgroundColor: '#FBFCFA',
        fontWeight: 'bold',
    },
    "&&:focus": {
        color: '#9a060d',
        borderColor: '#FBFCFA',
        fontWeight: 'bold',
    }
}));

export const OutlinedBlackButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2),  // 'my' en camelCase est 'marginY' pour MUI
    paddingTop: theme.spacing(1.5), // Manually setting padding-top
    paddingBottom: theme.spacing(1.5), // Manually setting padding-bottom
    paddingLeft: 25,
    paddingRight: 25,
    height: 'fit-content',
    fontWeight: 'bold',
    fontSize:'12px',
    borderRadius: 0,
    color: '#16191e',
    backgroundColor: 'transparent',
    borderColor: '#16191e',
    "&&:hover": {
        color: '#9a060d',
        borderColor: '#9a060d',
        backgroundColor: '#FBFCFA',
        fontWeight: 'bold',
    },
    "&&:focus": {
        color: '#9a060d',
        borderColor: '#FBFCFA',
        fontWeight: 'bold',
    }
}));

export const OutlinedRedButton = styled(Button)(({ theme }) => ({
    margin: 5,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 25,
    paddingRight: 25,
    height: 'fit-content',
    fontSize: '12px',
    borderRadius: 0,
    color: '#9d0208',
    backgroundColor: 'transparent',
    borderColor: '#9d0208',
    "&&:hover": {
        color: '#9d0208',
        borderColor: '#9d0208',
    },
    "&&:focus": {
        color: '#898b96',
        borderColor: '#898b96',
    }
}));


/* "&&:hover": {
    backgroundColor: "#FBFCFA",
    borderColor: "#9a060d",
    color: "#9a060d",
    fontWeight: 'bold',
},
"&&:focus": {
    backgroundColor: "#FBFCFA",
    borderColor: "#9a060d",
    color: "#9a060d",
} */