import FormationCard from "../../Components/FormationCard";
import { CircularProgress, Grid } from '@mui/material'
import api from "../../Api/api";
import { useEffect, useState } from "react";
import BoxText from "../../Components/BoxText";
import Box from '@mui/material/Box';

export default function CardContainer() {

    const [ateliers, setAteliers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAteliers = async () => {
            const listAteliers = await api.getAllProduitByType('atelier');
            const filteredListAteliers = listAteliers
                .filter((el) => {
                    const [datePart, timePart] = el.date.split(' ');
                    const [day, month, year] = datePart.split('/').map(Number);
                    let [hour, minute] = timePart ? timePart.split(':').map(Number) : [0, 0];
                    const dateAtelier = new Date(year, month - 1, day, hour='00', minute='00');
                    const today = new Date();
                    return (dateAtelier > today);
                })
                .sort((a, b) => (b.totalUnits - b.bookedUnits) - (a.totalUnits - a.bookedUnits));
            setAteliers(filteredListAteliers);
            setIsLoading(false);
        };
        fetchAteliers();
    }, []);

    return (
        <Box sx={{ backgroundColor: 'transparent', position: 'relative', zIndex: 1}}>
        {isLoading && <CircularProgress color="inherit"/>}
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            align='center'
        >
            {isLoading ? null : (
                ateliers.length ? 
                    ateliers.map((el, index) => (
                        <Grid
                        key={index}
                        item
                        sx={{
                            padding: 1,
                            rowSpacing: 2,
                            columnSpacing: 2,
                            align: 'center',
                            direction:"row",
                            justifyContent:"center",
                            alignItems: "center",
                        }}
                        xs="12" md="6" lg="6">
                            <FormationCard product={{...el}} />
                        </Grid>
                    ))
                    : <Grid item xs={12} sx={{ textAlign: 'center', alignItems: 'center' }}>
                        <BoxText 
                            title='Aucun atelier'
                            body="Pas d'atelier disponible pour le moment, revenez plus tard pour voir nos prochains ateliers et n'hésitez pas à nous contacter pour toute demande"
                            link='/contact'
                            textButton="Nous contacter"
                        />
                    </Grid>
            )}
        </Grid>
        </Box>
    );
}