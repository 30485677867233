import React from 'react';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player';

export const VideoPlayer = ({link}) => {

   return (
      <Box
         sx={{
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         marginX: 'auto',
         marginY: 2,
         width: { xs: '90%', md: '50%' }, // Largeur adaptative
         position: 'relative', // Nécessaire pour aspectRatio
         aspectRatio: '16/9', // Maintient le ratio 16:9
         }}
      >
         <ReactPlayer
            url={link}
            width="100%"
            height="100%"       
         />
      </Box>
   )
}

export default VideoPlayer