import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 0,
      background: '#9a060d',
      color: '#FBFCFA',
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}));

export const StyledBadgeRed = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 0,
      background: '#4e050d',
      color: '#ffffff',
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}));